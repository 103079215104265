import { defineStore } from 'pinia'
import { type User } from '~/types/user'
import { apiStore } from './api'

export const usersStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'users',
  // a function that returns a fresh state
  state: () => ({
    users: [] as User[],
  }),

  // optional getters
  getters: {
    supportedRoles: () => {
      return [
        {
          label: 'User',
          value: 'user',
        },
        {
          label: 'Admin',
          value: 'admin',
        },
        {
          label: 'Catalog Manager',
          value: 'catalog_manager',
        },
        {
          label: 'Project Manager',
          value: 'project_manager',
        },
        {
          label: 'Sourcing Manager',
          value: 'sourcing_manager',
        },
      ]
    },
  },
  // optional actions
  actions: {
    async loadUsers(): Promise<User[]> {
      try {
        const api = apiStore().getApiClient
        const users = await api.getUsersPhp()
        this.users = users.data.map((user: User) => {
          const splitName = user?.name?.split(' ')
          return {
            ...user,
            first_name: splitName[0],
            last_name: splitName.slice(1).join(' '),
          }
        })
      } catch (error: unknown) {
        this.users = []
        throw error
      }
    },
    filterUsers(ids: string) {
      const users: User[] = this.users.filter((u: User) => {
        return ids?.length && ids?.includes(u?.id)
      })
      return users
    },
    getUser(user_id: string): User {
      return this.users.find(user => user.id === user_id)
    },
    getProjectManagers() {
      return this.users.filter(user => user.roles.includes('project_manager'))
    },
    getCatalogManagers() {
      return this.users.filter(user => user.roles.includes('catalog_manager'))
    },
    getAdmins() {
      return this.users.filter(user => user.roles.includes('admin'))
    },
  },
})
