<script setup lang="ts">
/**
 * @modelValue : String
 * (The searched value)
 *
 * @placeholder : String
 * (The placeholder of the search bar)
 */

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
    default: '',
  },
  placeholder: {
    type: String,
    required: false,
  },
  class: {
    type: String,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue', 'blur'])

const { t } = useI18n()

const data = reactive({
  searchedValue: '',
})

const searchInput = ref<HTMLInputElement | null>(null)

defineExpose({
  searchInput,
})

function loadData() {
  if (props.modelValue) data.searchedValue = props.modelValue
}

onMounted(() => {
  loadData()
})
</script>

<template>
  <div class="relative flex items-center w-full text-primary">
    <input
      ref="searchInput"
      v-model="data.searchedValue"
      :placeholder="props.placeholder ?? t('global.search')"
      class="flex w-full items-center gap-2 py-1.5 pr-8 pl-4 text-sm text-gray-900 border border-gray-100 rounded transition cursor-pointer placeholder:text-gray-400 focus-visible:border-blue-500"
      :class="[props.class, props.disabled ? '' : 'hover:border-blue-500']"
      :disabled="props.disabled"
      @input="emit('update:modelValue', data.searchedValue)"
      @blur="emit('blur')"
    />
    <div class="absolute right-0 flex items-center px-2 py-[7px] text-gray-900 border-l pointer-events-none">
      <div i="carbon-search" class="h-4" />
    </div>
  </div>
</template>
