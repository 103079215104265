<script setup lang="ts">
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { nextTick, onMounted, onUnmounted, ref, watch } from 'vue'

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  maxWidth: {
    type: Number,
    required: false,
    default: 616,
  },
  zIndex: {
    type: Number,
    required: false,
    default: 9999,
  },
  dismissOnEscape: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const initialPosition = { x: 0, y: 0 }

// Initialize position for center alignment
const position = ref(initialPosition)
const isDragging = ref(false)
const isMouseDown = ref(false)
const dragStart = ref({ x: 0, y: 0 })
const initialMousePos = ref({ x: 0, y: 0 })
const dragTimer = ref<number | null>(null)
const DRAG_THRESHOLD = 5 // pixels
const DRAG_DELAY = 100 // milliseconds

const lastClickTime = ref(0)
const DOUBLE_CLICK_TIMEOUT = 300

function calculateDistance(p1: { x: number; y: number }, p2: { x: number; y: number }) {
  const dx = p1.x - p2.x
  const dy = p1.y - p2.y
  return Math.sqrt(dx * dx + dy * dy)
}

// Drag handlers
function startDrag(e: MouseEvent) {
  // Only handle left mouse button (button === 0)
  if (e.button !== 0) return

  // Prevent drag on double click
  const clickTime = Date.now()
  if (clickTime - lastClickTime.value < DOUBLE_CLICK_TIMEOUT) {
    lastClickTime.value = 0
    return
  }
  lastClickTime.value = clickTime

  isMouseDown.value = true
  initialMousePos.value = { x: e.clientX, y: e.clientY }
  dragStart.value = {
    x: e.clientX - position.value.x,
    y: e.clientY - position.value.y,
  }

  // Start drag timer
  if (dragTimer.value) clearTimeout(dragTimer.value)
  dragTimer.value = setTimeout(() => {
    const currentDistance = calculateDistance({ x: e.clientX, y: e.clientY }, initialMousePos.value)
    if (currentDistance >= DRAG_THRESHOLD) {
      isDragging.value = true
    }
  }, DRAG_DELAY)
}

function onDrag(e: MouseEvent) {
  if (!isMouseDown.value) return

  const currentDistance = calculateDistance({ x: e.clientX, y: e.clientY }, initialMousePos.value)

  if (currentDistance >= DRAG_THRESHOLD) {
    isDragging.value = true
  }

  position.value = {
    x: e.clientX - dragStart.value.x,
    y: e.clientY - dragStart.value.y,
  }
}

function stopDrag() {
  isMouseDown.value = false
  isDragging.value = false
  if (dragTimer.value) {
    clearTimeout(dragTimer.value)
    dragTimer.value = null
  }
}

const modalRef = ref<HTMLElement | null>(null)

watch(
  () => props.open,
  async isOpen => {
    if (isOpen) {
      // Reset to center
      position.value = initialPosition
      await nextTick()
    }
  },
)

const { escape } = useMagicKeys()
const emit = defineEmits<{
  (e: 'close'): void
}>()

function closeModal() {
  emit('close')
}

watchEffect(() => {
  if (escape.value && props.dismissOnEscape) {
    closeModal()
  }
})

// Add event listeners
onMounted(() => {
  document.addEventListener('mousemove', onDrag)
  document.addEventListener('mouseup', stopDrag)
})

onUnmounted(() => {
  document.removeEventListener('mousemove', onDrag)
  document.removeEventListener('mouseup', stopDrag)
})
</script>

<template>
  <TransitionRoot as="modalTemplate" :show="props.open">
    <Dialog
      as="modalDiv"
      class="fixed inset-0"
      :style="{
        zIndex: props.zIndex,
      }"
      @close="() => {}"
    >
      <div class="fixed inset-0 overflow-hidden">
        <!-- Backdrop - stays fixed -->
        <TransitionChild
          as="modalTemplate"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 transition-opacity"
            :class="[isDragging ? 'bg-opacity-10' : 'bg-opacity-75']"
            @click="closeModal()"
          />
        </TransitionChild>

        <!-- Modal Content - draggable -->
        <div
          ref="modalRef"
          class="absolute left-1/2 top-1/2"
          :style="{
            transform: `translate(calc(-50% + ${position.x}px), calc(-50% + ${position.y}px))`,
          }"
        >
          <TransitionChild
            as="modalTemplate"
            enter="ease-out duration-300"
            enter-from="opacity-0 sm:scale-95"
            enter-to="opacity-100 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 sm:scale-100"
            leave-to="opacity-0 sm:scale-95"
          >
            <div class="bg-white rounded-lg shadow-xl">
              <!-- Draggable area -->

              <div
                class="inline-block max-h-[95vh] p-6 w-[80vw] bg-white rounded-lg text-left shadow-xl transform transition-all"
                :style="{ maxWidth: `${props.maxWidth}px` }"
                @mousedown="startDrag"
              >
                <slot name="content" />
                <div v-if="$slots.footer" class="flex mt-6">
                  <slot name="footer" />
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
.modal-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  cursor: move;
}

.modal-header {
  padding: 1rem;
  cursor: move;
  user-select: none;
}
</style>
